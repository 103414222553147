<template>
  <div id="msg-list" class="msg-list" @touchstart="touchList">
    <van-pull-refresh v-model="isLoading" @refresh="getHistoryMsg">
      <div class="list" :class="{ more: moreStatus, emoji: emojiStatus }">
        <div v-for="item in chatMsg" :key="item.id" class="item" :class="{ my: item.style == 'self' }">
          <div class="avatar">
            <van-image
              round
              fit="cover"
              class="img"
              :src="item.style == 'self' ? userAvatar : salesmanAvatar"
              @click="goUserDetail(item)"
            />
          </div>
          <div class="cont">
            <template v-if="item.msg.type == 'location'">
              <div class="location" @click="goLocation(item.msg.ext)">
                <div class="location-cont">
                  <div class="title">{{ item.msg.ext.h5Address }}</div>
                  <div class="location-img" :style="`background-image:url(${item.msg.ext.locationLookImg})`" />
                </div>
              </div>
            </template>
            <template v-for="(msgItem, msgIndex) in item.msg.data" v-else>
              <template v-if="msgItem.type == 'emoji'">
                <img :key="msgIndex" :src="require(`@/assets/faces/${msgItem.data}`)" class="img">
              </template>
              <template v-else-if="msgItem.data.name == '[约面谈]' || msgItem.data.name == '约面谈'">
                <!-- <template v-if="msgItem.data.faceTime">
                  <div :key="msgIndex" class="face" @click="goDownload">
                    <div class="face-cont">
                      <div class="title">定位</div>
                      <div class="des">
                        收到一条定位消息
                      </div>
                    </div>
                    <div class="face-icon">
                      <img src="@/assets/chat/location1.png" class="face-img">
                    </div>
                  </div>
                </template> -->
                <template>
                  <!-- <div :key="msgIndex" class="face" @click="goDownload">
                    <div class="face-cont">
                      <div class="title">约面谈</div>
                      <div class="des">收到一条约面谈消息</div>
                    </div>
                    <div class="face-icon">
                      <img src="@/assets/chat/face.png" class="face-img">
                    </div>
                  </div> -->
                  <div :key="msgIndex" class="face" @click="goInterviewInfo(msgItem.data)">
                    <div class="face-info">
                      <div class="title">约面谈申请</div>
                      <div class="group">
                        <div class="it">
                          <div class="name">面谈时间：</div>
                          <div class="value">{{ msgItem.data.orderTime | parseTime }}</div>
                        </div>
                        <div class="it">
                          <div class="name">面谈地址：</div>
                          <div class="value">{{ msgItem.data.address }}</div>
                        </div>
                        <div class="it">
                          <div class="name">备注信息：</div>
                          <div class="value">{{ msgItem.data.remark }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else-if="msgItem.data.name == '[贷款方案]' || msgItem.data.name == '贷款方案'">
                <div :key="msgIndex" class="face" @click="goLoanPlan">
                  <div class="face-cont">
                    <div class="title">贷款方案</div>
                    <div class="des">
                      <img v-if="msgItem.data.currentSendStatusText == '待确认'" src="@/assets/chat/warn.png" class="icon">
                      <img v-else src="@/assets/chat/serviced.png" class="icon">
                      {{ msgItem.data.currentSendStatusText || msgItem.data.content }}
                    </div>
                  </div>
                  <div class="face-icon">
                    <img src="@/assets/chat/loan.png" class="face-img">
                  </div>
                </div>
              </template>
              <template v-else-if="msgItem.data.name == '[初审材料]' || msgItem.data.name == '初审材料'">
                <div :key="msgIndex" class="face" @click="goMaterial(msgItem.data.name)">
                  <div class="face-cont">
                    <div class="title">初审材料</div>
                    <div class="des">
                      <img v-if="msgItem.data.currentSendStatusText == '未填写'" src="@/assets/chat/warn.png" class="icon">
                      <img v-else src="@/assets/chat/serviced.png" class="icon">
                      {{ msgItem.data.currentSendStatusText || msgItem.data.content }}
                    </div>
                  </div>
                  <div class="face-icon">
                    <img src="@/assets/chat/trial.png" class="face-img">
                  </div>
                </div>
              </template>
              <template v-else-if="msgItem.data.name == '[面签材料]' || msgItem.data.name == '面签材料'">
                <div :key="msgIndex" class="face" @click="goOrderProduct">
                  <div class="face-cont">
                    <div class="title">面签材料</div>
                    <div class="des">
                      <img v-if="msgItem.data.currentSendStatusText == '待填写'" src="@/assets/chat/warn.png" class="icon">
                      <img v-else src="@/assets/chat/serviced.png" class="icon">
                      {{ msgItem.data.currentSendStatusText || msgItem.data.content }}
                    </div>
                  </div>
                  <div class="face-icon">
                    <img src="@/assets/chat/face2.png" class="face-img">
                  </div>
                </div>
              </template>
              <template v-else-if="msgItem.data.name == '[服务协议]' || msgItem.data.name == '服务协议'">
                <div :key="msgIndex" class="face" @click="goService()">
                  <div class="face-cont">
                    <div class="title">服务协议</div>
                    <div class="des">
                      <img v-if="msgItem.data.currentSendStatusText" src="@/assets/chat/warn.png" class="icon">
                      <img v-else src="@/assets/chat/serviced.png" class="icon">
                      {{ msgItem.data.currentSendStatusText || msgItem.data.content }}
                    </div>
                  </div>
                  <div class="face-icon">
                    <img src="@/assets/chat/service2.png" class="face-img">
                  </div>
                </div>
              </template>
              <template v-else-if="msgItem.data.showContent == '是否结束贷款'">
                <div :key="msgIndex" class="face" @click="goEndConfirm(msgItem.data)">
                  <div class="face-cont">
                    <div class="title">结束贷款确认函</div>
                    <div class="des">
                      <img src="@/assets/chat/warn.png" class="icon">
                      待确认
                    </div>
                  </div>
                  <div class="face-icon">
                    <img src="@/assets/chat/over.png" class="face-img">
                  </div>
                </div>
              </template>
              <template v-else>
                <span v-if="msgItem.data == '对方邀请您签署服务协议，请先完成实名认证~'" :key="msgIndex" style="white-space: pre-wrap; line-height: 1;" @click="goUserAuthentication">
                  对方邀请您签署服务协议，请先完成<span style="color: #0078d4">实名认证</span>~
                </span>
                <span v-else :key="msgIndex" style="white-space: pre-wrap; line-height: 1;" v-html="msgItem.data" />
              </template>
            </template>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <van-popup v-model="show" class="iframe-popup">
      <iframe :src="signUrl" frameborder="0" width="100%" height="100%" />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import msgStorage from '@/components/chat/msgstorage'
import { getStorageSync, setStorageSync, goScrollBottom, parseTime } from '@/utils/index'
import { getOrderAllStatus, getFirstTrialStatus } from '@/api/product'
import { getOssUrl } from '@/utils/oss'
export default {
  filters: {
    parseTime(time) {
      return parseTime(time, '{y}/{m}/{d} {h}:{i}:{s}')
    }
  },
  props: {
    salemansInfo: {
      type: Object,
      default: () => ({})
    },
    moreStatus: {
      type: Boolean,
      default: false
    },
    emojiStatus: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: ''
    },
    lastOrderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      index: 0,
      page: 0,
      chatMsg: [],
      curChatMsg: null,
      userAvatar: '',
      salesmanAvatar: '',
      signUrl: '',
      show: false
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  beforeDestroy() {
    console.log('销毁')
    msgStorage.off('newChatMsg', this.dispMsg)
  },
  async mounted() {
    this.userAvatar = await getOssUrl(this.userInfos.headImgUrl)
    this.salesmanAvatar = await getOssUrl(this.salemansInfo.headImgUrl)
    const { your } = this.$route.params
    const myUsername = this.userInfos.eaUserName

    const sessionKey = your + myUsername

    const chatMsg = getStorageSync(sessionKey) || []
    this.renderMsg(null, null, chatMsg, sessionKey)
    setStorageSync(sessionKey, null)
    this.goScrollBottom()
    msgStorage.on('newChatMsg', this.dispMsg)
  },
  methods: {
    dispMsg(renderableMsg, type, curChatMsg, sesskey) {
      const { your } = this.$route.params
      const myUsername = this.userInfos.eaUserName
      const sessionKey = your + myUsername
      this.curChatMsg = curChatMsg
      if (sesskey === sessionKey) {
        this.renderMsg(renderableMsg, type, curChatMsg, sessionKey, 'newMsg')
      }
    },
    goUserAuthentication() {
      this.$router.push({ name: 'userAuthentication' })
    },
    goDownload() {
      this.$dialog.confirm({
        title: '提示',
        message: '建议下载APP体验更优质的服务',
        confirmButtonText: '前去下载',
        confirmButtonColor: '#FF7700'
      })
        .then(() => {
          window.location.href = 'http://47.107.64.224/h5/download/index.html'
        })
        .catch(() => {
          // on cancel
        })
    },
    goUserDetail(item) {
      console.log(item)
      if (item.style === 'self') {
        this.$router.push({ name: 'userInfo' })
      } else {
        this.$router.push({ name: 'salesmanInfo', params: { saleId: item.msg.ext.userId }})
      }
    },
    goOrderProduct() {
      this.$router.push({
        name: 'chatOrderProduct',
        query: {
          orderId: this.orderId
        }
      })
    },
    async goEndConfirm(msgData) {
      console.log(msgData, '222')
      const res = await getOrderAllStatus({ orderId: msgData.orderId, saleId: this.salemansInfo.userId })
      const loanStatus = res.datas && res.datas.loanStatus
      if (loanStatus === 0) {
        this.$router.push({
          name: 'loanEndConfirm',
          query: {
            orderId: this.orderId
          },
          params: {
            msgData
          }
        })
      } else {
        this.$toast(`您已操作${loanStatus === 1 ? '继续贷款' : '结束贷款'}`)
      }
    },
    async goService() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res2 = await getOrderAllStatus({ orderId: this.orderId, saleId: this.salemansInfo.userId })
      loading.clear()
      const isOss = res2.datas.signStatus === 4
      if (isOss) {
        this.$router.push({ name: 'lookPdf', params: { pdf: res2.datas.signUrl }})
      } else {
        window.location.href = res2.datas.signUrl
      }
    },
    async goMaterial(name) {
      if (name === '[初审材料]') {
        const res = await getFirstTrialStatus({ orderId: this.orderId })
        console.log(res)
        if (res.datas && (res.datas === 2)) {
          this.$router.push({
            name: 'chatMaterial',
            query: {
              orderId: this.orderId
            }
          })
        }
      } else {
        this.$router.push({
          name: 'chatLookMaterial',
          query: {
            orderId: this.orderId
          }
        })
      }
    },
    async goLoanPlan() {
      this.$router.push({
        name: 'chatLoanPlan',
        query: {
          orderId: this.orderId
        }
      })
    },
    goLocation(location) {
      this.$router.push({ name: 'chatLookLocation', params: { location }})
    },
    goScrollBottom() {
      goScrollBottom('msg-list')
    },
    touchList() {
      this.$emit('touchList')
    },
    async getHistoryMsg() {
      const { your } = this.$route.params
      const myUsername = this.userInfos.eaUserName
      const sessionKey = your + myUsername
      const historyChatMsgs = getStorageSync('rendered_' + sessionKey) || []

      if (this.index < historyChatMsgs.length) {
        const timesMsgList = historyChatMsgs.slice(-this.index - 10, -this.index)
        this.chatMsg = timesMsgList.concat(this.chatMsg)
        this.index += timesMsgList.length
        if (timesMsgList.length === 10) {
          this.page++
        }
        for (let i = 0; i < this.chatMsg.length; i++) {
          this.$set(this.chatMsg[i].msg.ext, 'locationLookImg', await getOssUrl(this.chatMsg[i].msg.ext.locationImg))
        }
      } else {
        this.$toast('无更多数据')
      }
      this.isLoading = false
    },
    goInterviewInfo(infos) {
      this.$router.push({
        name: 'chatInterviewInfo',
        params: {
          infos
        }
      })
    },
    async renderMsg(renderableMsg, type, curChatMsg, sessionKey, isnew) {
      const types = ['7', '8', '12', '13', '9', '14', '18'] // 7 面谈  8 服务协议 12 面签材料 13 贷款方案 9 初审材料 14 是否结束贷款 18 定位
      for (let i = 0; i < curChatMsg.length; i++) {
        const msgType = curChatMsg[i].msg.ext.type
        if (types.includes(msgType)) {
          curChatMsg[i].msg.data[0].data = JSON.parse(curChatMsg[i].msg.data[0].data)
        }
        if (curChatMsg[i].msg.type === 'location') {
          curChatMsg[i].msg.data = 'location'
        }
      }
      var historyChatMsgs = getStorageSync('rendered_' + sessionKey) || []
      for (let i = 0; i < historyChatMsgs.length; i++) {
        const msgType = historyChatMsgs[i].msg.ext.type
        if (types.includes(msgType)) {
          try {
            historyChatMsgs[i].msg.data[0].data = JSON.parse(historyChatMsgs[i].msg.data[0].data)
          } catch {
            //
          }
        }
        if (historyChatMsgs[i].msg.type === 'location') {
          historyChatMsgs[i].msg.data = 'location'
        }
      }
      historyChatMsgs = historyChatMsgs.concat(curChatMsg)
      if (!historyChatMsgs.length) return
      if (isnew === 'newMsg') {
        this.chatMsg = this.chatMsg.concat(curChatMsg)
      } else {
        this.chatMsg = historyChatMsgs.slice(-10)
      }

      setStorageSync('rendered_' + sessionKey, historyChatMsgs)

      const chatMsg = getStorageSync(sessionKey) || []
      chatMsg.map(function(item, index) {
        curChatMsg.map(function(item2) {
          if (item2.mid === item.mid) {
            chatMsg.splice(index, 1)
          }
        })
      })

      setStorageSync(sessionKey, chatMsg)
      this.index = historyChatMsgs.slice(-10).length
      for (let i = 0; i < this.chatMsg.length; i++) {
        this.$set(this.chatMsg[i].msg.ext, 'locationLookImg', await getOssUrl(this.chatMsg[i].msg.ext.locationImg))
      }
      setTimeout(() => {
        this.goScrollBottom()
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.msg-list {
  height: 100%;
  overflow-y: auto;
  .list {
    padding-bottom: 100px;
    &.emoji {
      padding-bottom: 250px;
    }
    &.more {
      padding-bottom: 220px;
    }
    .item {
      display: flex;
      align-items: flex-start;
      padding: 16px;
      &.my {
        flex-direction: row-reverse;
        .avatar {
          padding-right: 0;
          padding-left: 10px;
        }
        .cont {
          margin-left: 50px;
          margin-right: 0;
          color: #fff;
          border-radius: 8px 0px 8px 8px;
          background: #FFB367;
          .face-info {
            .title {
              color: #fff;
            }
            .group {
              .value {
                color: #fff;
              }
            }
          }
        }
      }
      .avatar {
        padding-right: 10px;
        .img {
          width: 40px;
          height: 40px;
        }
      }
      .cont {
        padding: 10px;
        border-radius: 0px 8px 8px 8px;
        margin-right: 50px;
        line-height: 20px;
        font-size: 14px;
        background: #F5F5F7;
        .img {
          position: relative;
          top: 4px;
          width: 20px;
          height: 20px;
          margin-right: 2px;
        }
      }
    }
  }
}
.face {
	width: 225px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.face .face-cont {
  flex: 1;
}
.face .face-cont .title {
	font-size: 16px;
	color: #2E3135;
}
.face .face-cont .des {
	display: flex;
	align-items: center;
	padding-top: 5px;
	font-size: 12px;
	color: #666666;
}
.face .face-cont .des .icon {
	width: 15px;
	height: 15px;
	margin-right: 5px;
}
.face .face-icon {
	width: 62px;
	height: 62px;
	margin: 0;
	margin-left: 10px;
}
.face .face-icon .face-img {
	width: 62px;
	height: 62px;
}

.iframe-popup {
  width: 80%;
  height: 80%;
}

.face-info {
  width: 100%;
  height: 100%;
  padding: 10px;
  .title {
    font-size: 16px;
    color: #2E3135;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .it {
    display: flex;
    padding: 5px 0;
    .name {
      width: 70px;
    }
    .value {
      flex: 1;
      font-size: 14px;
      color: #666666;
    }
  }
}
.location {
  .location-cont {
    .title {
      font-size: 14px;
      padding-bottom: 5px;
    }
    .location-img {
      width: 250px;
      height: 150px;
      background-position: center center;
      background-size: 250px;
    }
  }
}
</style>
