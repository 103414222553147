<template>
  <div class="input-bar">
    <div class="group">
      <div class="input">
        <van-field
          ref="msg"
          v-model="inputMessage"
          rows="1"
          autosize
          class="text"
          type="textarea"
          placeholder="请输入对话内容"
          @focus="msgFocus"
          @input="msgInput"
        />
      </div>
      <div class="emoji" @click="toggleEmojiStatus">
        <img src="@/assets/chat/emoji@2x.png" class="icon">
      </div>
      <div v-if="moreBtnStatus" class="more" @click="toggleMoreStatus">
        <img src="@/assets/chat/more@2x.png" class="icon">
      </div>
      <div v-else class="send">
        <div class="btn" @click="sendMessage">发送</div>
      </div>
    </div>
    <div v-if="emojiStatus" class="emoji-box">
      <Emoji @emojiAction="emojiAction" />
    </div>
    <div v-show="moreStatus" class="more-box">
      <div class="list">
        <div class="item" @click="goTel">
          <div class="icon">
            <img v-if="callStatus" src="@/assets/chat/phone.png" class="img">
            <img v-else src="@/assets/chat/phone_hide.png" class="img">
          </div>
          <div class="title">拨打电话</div>
        </div>
        <div class="item" @click="goLocation">
          <div class="icon">
            <img v-if="locationBtnStatus" src="@/assets/chat/icon_01.png" class="img">
            <img v-else src="@/assets/chat/icon_01h.png" class="img">
          </div>
          <div class="title">定位</div>
        </div>
        <div class="item" @click="goFace">
          <div class="icon">
            <img v-if="faceBtnStatus" src="@/assets/chat/icon_06.png" class="img">
            <img v-else src="@/assets/chat/icon_06h.png" class="img">
          </div>
          <div class="title">约面谈</div>
        </div>
        <div class="item" @click="goService">
          <div class="icon">
            <img v-if="serviceBtnStatus" src="@/assets/chat/service.png" class="img">
            <img v-else src="@/assets/chat/service_h.png" class="img">
          </div>
          <div class="title">服务协议</div>
        </div>
        <div class="item" @click="goMaterial">
          <div class="icon">
            <img v-if="materialBtnStatus" src="@/assets/chat/icon_05.png" class="img">
            <img v-else src="@/assets/chat/icon_05h.png" class="img">
          </div>
          <div class="title">初审材料</div>
        </div>
        <div class="item" @click="goFaceSign">
          <div class="icon">
            <img v-if="faceSignBtnStatus" src="@/assets/chat/icon_04.png" class="img">
            <img v-else src="@/assets/chat/icon_04h.png" class="img">
          </div>
          <div class="title">面签材料</div>
        </div>
        <div class="item" @click="goLoanProgress">
          <div class="icon">
            <img v-if="loanBtnStatus" src="@/assets/chat/icon_03.png" class="img">
            <img v-else src="@/assets/chat/icon_03h.png" class="img">
          </div>
          <div class="title">贷款进度</div>
        </div>
        <div class="item" @click="goAgain">
          <div class="icon">
            <img v-if="againBtnStatus" src="@/assets/chat/icon_02.png" class="img">
            <img v-else src="@/assets/chat/icon_02h.png" class="img">
          </div>
          <div class="title">再贷一次</div>
        </div>
        <template v-if="changeStatus">
          <div class="item" @click="selectSalesman">
            <div class="icon">
              <img src="@/assets/chat/salesman_select.png" class="img">
              <!-- <img v-else src="@/assets/chat/salesman_select_hide.png" class="img"> -->
            </div>
            <div class="title">选定经理人</div>
          </div>
          <div class="item" @click="changeSalesman">
            <div class="icon">
              <img src="@/assets/chat/salesman_change.png" class="img">
              <!-- <img v-if="count != 0 && changeStatus" src="@/assets/chat/salesman_change.png" class="img"> -->
              <!-- <img v-else src="@/assets/chat/salesman_change_hide.png" class="img"> -->
            </div>
            <div class="title">更换经理人</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable new-cap */
import msgStorage from '@/components/chat/msgstorage'
import msgType from '@/components/chat/msgtype'
import WebIM from '@/utils/WebIM'
import disp from '@/utils/broadcast'
import Emoji from './components/Emoji'
import { getOrderAllStatus, getFirstTrialStatus } from '@/api/product'
import { checkCity, orderState } from '@/api/user'
import { parseTime } from '@/utils'
import { mapGetters } from 'vuex'
import { getLocalUserInfo } from '@/utils/auth'
// import { orderStatus } from '../../../../filters'
export default {
  components: { Emoji },
  props: {
    moreStatus: {
      type: Boolean,
      default: false
    },
    emojiStatus: {
      type: Boolean,
      default: false
    },
    salemansInfo: {
      type: Object,
      default: () => ({})
    },
    orderId: {
      type: String,
      default: ''
    },
    lastOrderId: {
      type: String,
      default: ''
    },
    changeStatus: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      inputMessage: '',
      userMessage: '',
      moreBtnStatus: true,
      callStatus: false,
      locationBtnStatus: false, // 定位按钮状态
      faceBtnStatus: false, // 约面谈按钮状态
      serviceBtnStatus: false, // 服务协议按钮状态
      materialBtnStatus: false, // 初审材料按钮状态
      faceSignBtnStatus: false, // 面签材料按钮状态
      loanBtnStatus: false, // 贷款进度按钮状态
      againBtnStatus: false // 再贷一次按钮状态
    }
  },
  computed: {
    ...mapGetters([
      'userInfos',
      'sendSelectMsg',
      'faceInfo',
      'planStatus',
      'faceSignStatus',
      'allOrderStatus',
      'locationInfo',
      'materialSendStatus',
      'sendLoanStatus',
      'serviceSendStatus'
    ])
  },
  async mounted() {
    const { sendHelloMsg } = this.$route.params
    if (sendHelloMsg) {
      this.userMessage = '我想跟您了解以下贷款方面的知识，我们可以聊聊吗？'
      setTimeout(() => {
        this.sendMessage({ type: '' })
      }, 500)
    }
    if (this.sendSelectMsg) {
      this.userMessage = '我已选定你为我的经理人'
      setTimeout(() => {
        this.sendMessage({ type: '' })
        this.$store.dispatch('user/setSelectMsgStatus', false)
      }, 500)
    }
    const faceInfo = this.faceInfo
    if (faceInfo.status) {
      console.log(faceInfo, '00000')
      this.userMessage = `{"address": "${faceInfo.address}", "isSigned": "${faceInfo.isSigned}", "addressImgUrl": "${faceInfo.locationImg}", "longitude":"${faceInfo.longitude}", "latitude":"${faceInfo.latitude}", "orderTime": "${faceInfo.orderTime}", "opSource":"3", "name":"[约面谈]", "remark":" ${faceInfo.remark}"}`
      setTimeout(() => {
        this.sendMessage({ type: '7' })
        this.$store.dispatch('user/setFaceInfo', { status: false })
      }, 500)
    }
    if (this.planStatus) {
      this.userMessage = `{"name":"贷款方案", "currentStatus": "已确认", "currentSendStatusText": "已确认", "orderId": "${this.orderId}"}`
      setTimeout(() => {
        this.sendMessage({ type: '13' })
        this.$store.dispatch('user/setPlanStatus', false)
      }, 500)
    }
    if (this.materialSendStatus) {
      this.userMessage = `{"name":"初审材料", "currentStatus": "已拟定", "currentSendStatusText": "已拟定", "orderId": "${this.orderId}"}`
      setTimeout(() => {
        this.sendMessage({ type: '9' })
        this.$store.dispatch('user/setMaterialSendStatus', false)
      }, 500)
    }
    if (this.serviceSendStatus) {
      const sendTime = parseTime(new Date(), '{y}-{m}-{d} {h}:{i}')
      this.userMessage = `{"name":"服务协议", "currentStatus": "已签订", "currentSendStatusText": "", "orderId": "${this.orderId}", "sendTime": "${sendTime}"}`
      setTimeout(() => {
        this.sendMessage({ type: '8' })
        this.$store.dispatch('user/setServiceSendStatus', false)
      }, 500)
    }
    if (this.sendLoanStatus) {
      this.userMessage = `我还需要贷款，请继续`
      setTimeout(() => {
        this.sendMessage({ type: '' })
        this.$store.dispatch('user/setSendLoanStatus', false)
      }, 500)
    }
    if (this.faceSignStatus) {
      const sendTime = parseTime(new Date(), '{y}-{m}-{d} {h}:{i}')
      this.userMessage = `{"name":"面签材料", "currentStatus": "已填写", "sendTime": "${sendTime}", "currentSendStatusText": "已填写", "orderId": "${this.orderId}"}`
      setTimeout(() => {
        console.log(JSON.stringify(this.userMessage))
        this.sendMessage({ type: '12' })
        this.$store.dispatch('user/setFaceSignStatus', false)
      }, 500)
    }
    const locationInfo = this.locationInfo
    if (locationInfo.status) {
      console.log('location')
      this.userMessage = 'location'
      setTimeout(() => {
        this.sendMessage({ type: '100', isLocation: true })
        this.$store.dispatch('user/setLocationInfo', { status: false })
      }, 500)
    }
    // 判断是否有过订单进入状态判断
    if (this.allOrderStatus.isOrder !== 2) {
      const {
        orderStatus, // 订单状态 -1没有该订单, 0-预发布，1-已发布， 2-已拒绝，3-已下架，4-沟通中，5-待确认，6-已完成，7-已取消，8-已删除'
        signStatus, // 1、未签约 2、自己签约成功 3、双方签约成功 4、已生成合同文件
        signStatus2, // 签署状态 1、未签约 2、已签约
        agreementType, // 协议入门 1.服务协议（对应签服务协议进来的）/订单完成或者无订单关系 2.线下协议（暂无这个状态） 3.线下见面协议（对应约面谈进来的的）/有订单关系
        userUploadOrView, // 用户是否上传初审材料
        reFinancing, // 再贷一次 1不显示
        hasConfirmPlan, // 是否可以点击贷款进度
        faceMaterials, // 面签材料是否可以点击
        saleSendFaceMaterials, // 1-业务员已发送面签材料（由于之前IOS猫嘀嘀已经调好，所以不动之前的字段逻辑）0-业务员未发送面签材料
        signUrl, // 服务协议的连接地址
        isDone, // 是否完成订单 1完成 2未完成
        whetherInterview // 是否完成订单 1完成 2未完成
      } = this.allOrderStatus
      console.log(faceMaterials, signUrl)
      if (isDone === 1) {
        this.locationBtnStatus = false
        this.faceBtnStatus = false
        this.serviceBtnStatus = true
        if (!userUploadOrView) {
          this.materialBtnStatus = false
        } else {
          this.materialBtnStatus = true
        }
        if (saleSendFaceMaterials === 1) {
          this.faceSignBtnStatus = true
        } else {
          this.faceSignBtnStatus = false
        }
        this.loanBtnStatus = true
        if (orderStatus === 4) {
          this.againBtnStatus = false
        } else {
          this.againBtnStatus = true
        }
      } else {
        if (signStatus === 3 || signStatus === 4) {
          if (isDone === 2) {
            this.locationBtnStatus = true
            if (whetherInterview === 1) {
              this.faceBtnStatus = true
            } else {
              this.faceBtnStatus = false
            }
          } else {
            this.locationBtnStatus = false
            this.faceBtnStatus = false
          }
          this.serviceBtnStatus = true
          if (!userUploadOrView) {
            this.materialBtnStatus = false
          } else {
            this.materialBtnStatus = true
          }
          console.log(this.materialBtnStatus, '=============')
          if (saleSendFaceMaterials === 1) {
            this.faceSignBtnStatus = true
          } else {
            this.faceSignBtnStatus = false
          }
          if (hasConfirmPlan === 2) {
            this.loanBtnStatus = true
          } else {
            this.loanBtnStatus = false
          }
          if (reFinancing === 1) {
            this.againBtnStatus = false
          } else {
            this.againBtnStatus = true
          }
        } else {
          this.locationBtnStatus = false
          if (agreementType === 1) {
            if (whetherInterview === 1) {
              this.faceBtnStatus = true
            } else {
              this.faceBtnStatus = false
            }
            if (signStatus2 === 1) {
              this.serviceBtnStatus = false
            } else {
              this.serviceBtnStatus = true
            }
          } else if (agreementType === 3) {
            if (whetherInterview === 1) {
              this.faceBtnStatus = true
            } else {
              this.faceBtnStatus = false
            }
            if (signStatus2 !== 3) {
              this.serviceBtnStatus = false
            } else {
              this.serviceBtnStatus = true
            }
          } else {
            if (whetherInterview === 1) {
              this.faceBtnStatus = true
            } else {
              this.faceBtnStatus = false
            }
            this.serviceBtnStatus = false
          }
          this.materialBtnStatus = false
          this.faceSignBtnStatus = false
          this.loanBtnStatus = false
          if (reFinancing === 1) {
            this.againBtnStatus = false
          } else {
            this.againBtnStatus = true
          }
        }
      }
    }
    this.callStatus = this.salemansInfo.callStatus
    console.log(this.materialBtnStatus, '------')
  },
  methods: {
    goDownload() {
      this.$dialog.confirm({
        title: '提示',
        message: '建议下载APP体验更优质的服务',
        confirmButtonText: '前去下载',
        confirmButtonColor: '#FF7700'
      })
        .then(() => {
          window.location.href = 'http://47.107.64.224/h5/download/index.html'
        })
        .catch(() => {
          // on cancel
        })
    },
    async goService() {
      if (!this.serviceBtnStatus) return
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res2 = await getOrderAllStatus({ orderId: this.orderId, saleId: this.salemansInfo.userId })
      loading.clear()
      const isOss = res2.datas.signStatus === 4
      if (isOss) {
        this.$router.push({ name: 'lookPdf', params: { pdf: res2.datas.signUrl }})
      } else {
        window.location.href = res2.datas.signUrl
      }
    },
    async goMaterial() {
      if (!this.materialBtnStatus) return
      const { signStatus, userUploadOrView, isDone } = this.allOrderStatus
      let type = ''
      if (signStatus === 3 || signStatus === 4) {
        if (isDone === 2) {
          type = userUploadOrView
        } else {
          type = 1
        }
      }
      console.log(type, '----222')
      if (type === 1) {
        this.$router.push({
          name: 'chatLookMaterial',
          query: {
            orderId: this.orderId
          }
        })
      } else {
        const res = await getFirstTrialStatus({ orderId: this.orderId })
        console.log(res)
        if (res.datas && (res.datas === 2)) {
          this.$router.push({
            name: 'chatMaterial',
            query: {
              orderId: this.orderId
            }
          })
        }
      }
      console.log('初审材料')
    },
    goFaceSign() {
      if (!this.faceSignBtnStatus) return
      this.$router.push({
        name: 'chatOrderProduct',
        query: {
          orderId: this.orderId
        }
      })
      console.log('面签资料')
    },
    goLoanProgress() {
      if (!this.loanBtnStatus) return
      this.$router.push({ name: 'loanProgress', params: { orderId: this.orderId }, query: { isChat: true, saleId: this.salemansInfo.userId }})
      console.log('贷款进度')
    },
    async goAgain() {
      if (!this.againBtnStatus) return
      const res = await checkCity({ saleId: this.salemansInfo.userId })
      if (res.datas === 1) {
        this.$dialog.confirm({
          title: '前去确认贷款信息',
          message: '温馨提示：再次贷款会默认选定当前经理人',
          confirmButtonText: '去确认'
        })
          .then(async() => {
            // on confirm
            const { city } = getLocalUserInfo()
            const res = await orderState(city)
            const lastOrderId = res.datas.lastOrderId
            this.$router.push({ name: 'loanEdit', query: { lastOrderId, salesmanId: this.salemansInfo.userId }})
          })
          .catch(() => {
            // on cancel
          })
      } else {
        // 该城市未开放处理
      }
      console.log('再贷一次')
    },
    goFace() {
      if (!this.faceBtnStatus) return
      this.$router.push({ name: 'chatInterview', query: { orderId: this.orderId, saleId: this.salemansInfo.userId, time: new Date().getTime() }})
    },
    goLocation() {
      if (!this.locationBtnStatus) return
      this.$router.push({ name: 'chatLocation' })
    },
    goTel() {
      if (!this.callStatus) return
      const { your } = this.$route.params
      window.location.href = `tel:${your}`
    },
    msgFocus() {
      this.$emit('focus')
    },
    touchList() {
      this.$emit('update:moreStatus', false)
      this.$emit('update:emojiStatus', false)
    },
    msgInput() {
      console.log(this.$refs.msg, '000')
      this.userMessage = this.inputMessage
      this.moreBtnStatus = this.inputMessage.trim() === ''
    },
    toggleEmojiStatus() {
      this.$emit('update:emojiStatus', !this.emojiStatus)
      this.$emit('update:moreStatus', false)
      this.$parent.goScrollBottom()
    },
    changeSalesman() {
      if (!this.changeStatus) return
      if (this.count === 0) return this.$toast('可更换经理人次数已达上限！')
      this.$router.push({ name: 'managerCity', query: { salesmanId: this.salemansInfo.userId }})
    },
    async selectSalesman() {
      if (!this.changeStatus) return
      this.$dialog.confirm({
        title: '提示',
        message: '选定经理人时，需要完善您的贷款信息',
        confirmButtonText: '前去完善',
        cancelButtonText: '再聊聊',
        confirmButtonColor: '#FF7700'
      })
        .then(async() => {
          const { city } = getLocalUserInfo()
          const res = await orderState(city)
          const orderId = res.datas.orderId
          const lastOrderId = res.datas.lastOrderId
          // on confirm
          if (orderId) {
            this.$router.push({ name: 'loanEdit', query: { orderId, salesmanId: this.salemansInfo.userId }})
          } else {
            if (lastOrderId) {
              this.$router.push({ name: 'loanEdit', query: { lastOrderId, salesmanId: this.salemansInfo.userId }})
            } else {
              this.$router.push({ name: 'loanInfo', query: { salesmanId: this.salemansInfo.userId }})
            }
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    async toggleMoreStatus() {
      this.$emit('update:moreStatus', !this.moreStatus)
      this.$emit('update:emojiStatus', false)
      this.$parent.goScrollBottom()
    },
    emojiAction(emoji) {
      var str
      var msglen = this.userMessage.length - 1
      if (emoji && emoji !== '[del]') {
        str = this.userMessage + emoji
      } else if (emoji === '[del]') {
        const start = this.userMessage.lastIndexOf('[')
        const end = this.userMessage.lastIndexOf(']')
        const len = end - start
        if (end !== -1 && end === msglen && len >= 3 && len <= 4) {
          str = this.userMessage.slice(0, start)
        } else {
          str = this.userMessage.slice(0, msglen)
        }
      }
      this.userMessage = str
      this.inputMessage = str
      if (this.inputMessage) {
        this.moreBtnStatus = false
      }
      this.$emit('update:moreStatus', false)
    },
    sendMessage({ type, isLocation }) {
      const locationInfo = this.locationInfo
      const me = this
      if (!this.userMessage.trim()) {
        return
      }
      const id = WebIM.conn.getUniqueId()
      const msg = new WebIM.message(isLocation ? msgType.LOCATION : msgType.TEXT, id)
      const userInfo = this.userInfos || {}
      const ext = { headImgUrl: userInfo.headImgUrl || '', realName: userInfo.realName, userId: userInfo.userId, isCustomer: 1, nickname: userInfo.nickname, type, orderId: this.orderId }
      const locationOption = {}
      if (locationInfo.status) {
        //
        ext.locationImg = locationInfo.locationImg
        ext.h5Address = locationInfo.address
        ext.h5Lat = locationInfo.lat
        ext.h5Lng = locationInfo.lng
        locationOption.addr = locationInfo.address
        locationOption.lat = locationInfo.lat
        locationOption.lng = locationInfo.lng
        locationOption.type = 'loc'
        locationOption.buildingName = ''
      }
      const option = {
        ...locationOption,
        msg: this.userMessage,
        from: this.userInfos.eaUserName,
        to: this.$route.params.your,
        ext,
        roomType: false,
        chatType: 'singleChat',
        success(id) {
          console.log('成功了')
          disp.fire('em.chat.sendSuccess', id, me.userMessage)
          me.saveSendMsg({ msg: msg, type: isLocation ? msgType.LOCATION : msgType.TEXT })
        },
        fail(id, serverMsgId) {
          console.log('失败了')
          console.log('id', id)
          console.log('serverMsgId', serverMsgId)
        }
      }
      msg.set(option)
      console.log(msg.body, 'bodybodybodybodybody')
      WebIM.conn.send(msg.body)
      this.userMessage = ''
      this.inputMessage = ''
      this.moreBtnStatus = true
    },
    saveSendMsg({ msg, type }) {
      msg.body.headImgUrl = this.salemansInfo.headImgUrl
      msg.body.realName = this.salemansInfo.realName
      console.log(msg, '----')
      msgStorage.saveMsg(msg, type)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  z-index: 999;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  background: #fff;
  .group {
    display: flex;
    align-items: center;
    .input {
      flex: 1;
      .text::v-deep {
        width: 100%;
        box-sizing: border-box;
        border: 0;
        font-size: 14px;
        border-radius: 4px;
        overflow: hidden;
        background: #F5F5F7;
        .van-field__control {
          overflow: hidden;
        }
        &::placeholder {
          color: #CCCCCC;
        }
      }
    }
    .emoji {
      margin-left: 16px;
      .icon {
        width: 25px;
        height: 25px;
      }
    }
    .more {
      margin-left: 16px;
      .icon {
        width: 25px;
        height: 25px;
      }
    }
    .send {
      margin-left: 16px;
      .btn {
        padding: 5px 10px;
        font-size: 14px;
        border: 2px solid #2e3135;
        border-radius: 4px;
      }
    }
  }
  .more-box {
    padding: 20px 0;
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 25%;
        padding-bottom: 20px;
        text-align: center;
        .icon {
          .img {
            width: 32px;
            height: 32px;
          }
        }
        .title {
          padding-top: 10px;
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }
}
</style>
