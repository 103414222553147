<template>
  <div class="chat">
    <MsgList
      v-if="salemansInfo.userId && orderLoading"
      ref="msgList"
      :all-order-status="allOrderStatus"
      :more-status="moreStatus"
      :emoji-status="emojiStatus"
      :order-id="orderId"
      :last-order-id="lastOrderId"
      :salemans-info="salemansInfo"
      @touchList="touchList"
    />
    <InputBar v-if="salemansInfo.userId && orderLoading" ref="inputBar" :change-status="changeStatus" :more-status.sync="moreStatus" :emoji-status.sync="emojiStatus" :order-id="orderId" :last-order-id="lastOrderId" :salemans-info="salemansInfo" :count="count" @focus="msgFocus" />
  </div>
</template>

<script>
import InputBar from './components/InputBar'
import MsgList from './components/MsgList'
import { orderState, hasThreePoints, countNum } from '@/api/user'
import { getOrderAllStatus, getUserSalesRelation } from '@/api/product'
import { getLocalUserInfo } from '@/utils/auth'
export default {
  components: { InputBar, MsgList },
  props: {
    salemansInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      moreStatus: false,
      emojiStatus: false,
      orderId: '',
      lastOrderId: '',
      orderStatus: 0,
      allOrderStatus: null, // 订单进度状态,对按钮进行状态判断
      orderLoading: false,
      changeStatus: false,
      count: 0,
      isOrder: 2 // 是否产生过订单
    }
  },
  async mounted() {
    this.$store.dispatch('user/getAllOrderStatus', {
      signStatus: 1,
      signStatus2: 1,
      agreementType: 0,
      userUploadOrView: '',
      reFinancing: 1,
      hasConfirmPlan: 1,
      faceMaterials: null,
      saleSendFaceMaterials: null,
      isDone: 1,
      isOrder: 2
    })
    await this.hasThreePoints()
    await this.getUserSalesRelation()
    if (this.isOrder === 1) {
      await this.orderState()
      await this.getOrderAllStatus()
    }
    this.orderLoading = true
  },
  methods: {
    touchList() {
      this.$refs.inputBar.touchList()
    },
    msgFocus() {
      this.$refs.inputBar.touchList()
      this.goScrollBottom()
    },
    goScrollBottom() {
      setTimeout(() => {
        this.$refs.msgList.goScrollBottom()
      }, 150)
    },
    async orderState() {
      const { city } = getLocalUserInfo()
      const res = await orderState(city)
      if (res.datas.status !== -1 && res.datas.status !== 0) {
        this.orderId = res.datas.orderId
      }
      this.lastOrderId = res.datas.lastOrderId
      this.orderStatus = res.status
    },
    async getOrderAllStatus() {
      const res = await getOrderAllStatus({ orderId: this.orderId, saleId: this.salemansInfo.userId })
      const allOrderStatus = res.datas
      allOrderStatus.orderStatus = this.orderStatus
      this.allOrderStatus = { ...allOrderStatus }
      this.$store.dispatch('user/getAllOrderStatus', allOrderStatus)
    },
    async hasThreePoints() {
      const res = await hasThreePoints({ userId: this.salemansInfo.userId })
      this.changeStatus = res.datas
      console.log(this.changeStatus, 'changestatus')
      if (this.changeStatus) {
        const res = await countNum()
        this.count = res.datas
      }
    },
    async getUserSalesRelation() {
      const res = await getUserSalesRelation({ salesEaUserName: this.salemansInfo.eaUserName })
      this.isOrder = res.datas.isOrder
      this.orderId = res.datas.orderId
    }
  }
}
</script>

<style lang="scss" scoped>
.chat {
  width: 100%;
  height: 100%;
  background: #fcfcfc;
}
</style>
