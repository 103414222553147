<template>
  <div class="emoji">
    <van-swipe class="my-swipe" indicator-color="#ffb367" :stop-propagation="false">
      <van-swipe-item>
        <div class="emoji-list">
          <div class="emoji_item">
            <img
              v-for="(item, index) in emojiObj.map1"
              :key="item"
              class="emoji-img"
              :data-index="index"
              :src="require(`@/assets/faces/${item}`)"
            >
          </div>
          <div class="emoji_item">
            <img
              v-for="(item, index) in emojiObj.map2"
              :key="item"
              :src="require(`@/assets/faces/${item}`)"
              class="emoji-img"
              :data-index="index"
            >
          </div>
          <div class="emoji_item">
            <img
              v-for="(item, index) in emojiObj.map3"
              :key="item"
              :src="require(`@/assets/faces/${item}`)"
              class="emoji-img"
              :data-index="index"
            >
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="emoji-list">
          <div class="emoji_item">
            <img
              v-for="(item, index) in emojiObj.map4"
              :key="item"
              :src="require(`@/assets/faces/${item}`)"
              class="emoji-img"
              :data-index="index"
            >
          </div>
          <div class="emoji_item">
            <img
              v-for="(item, index) in emojiObj.map5"
              :key="item"
              :src="require(`@/assets/faces/${item}`)"
              class="emoji-img"
              :data-index="index"
            >
          </div>
          <div class="emoji_item" style="">
            <img
              v-for="(item, index) in emojiObj.map6"
              :key="item"
              :src="require(`@/assets/faces/${item}`)"
              class="emoji-img"
              :data-index="index"
            >
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import WebIM from '@/utils/WebIM'
export default {
  data() {
    return {
      emoji: WebIM.Emoji,
      emojiObj: WebIM.EmojiObj,
      testval: ''
    }
  },
  mounted() {
    const _this = this
    const list = document.getElementsByClassName('emoji-img')
    for (let i = 0; i < list.length; i++) {
      const index = list[i].getAttribute('data-index')
      list[i].addEventListener('touchstart', function() { _this.sendEmoji(index) }, false)
    }
  },
  methods: {
    sendEmoji(index) {
      this.$emit('emojiAction', index)
    }
  }
}
</script>

<style lang="scss" scoped>
* { touch-action: pan-y; }
.emoji {
  .my-swipe::v-deep {
    .emoji-list {
      padding: 20px 0;
      .emoji_item {
        display: flex;
        justify-content: space-between;
        img {
          width: 26px;
          height: 26px;
          margin: 5px 0;
        }
      }
    }
    .van-swipe__indicators {
      bottom: 0;
    }
    .van-swipe__indicator {
      background: #bbb;
    }
  }
}
</style>
