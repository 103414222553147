<template>
  <Chat v-if="!loading" :salemans-info="salemansInfo" />
</template>

<script>
let salesmanInfoBak = null // 暂存业务员数据
import disp from '@/utils/broadcast'
import Chat from '@/components/chat/index.vue'
import { getUserInfo, bindUserFriend, getFriendBindState } from '@/api/user'
export default {
  components: { Chat },
  data() {
    return {
      salemansInfo: {},
      loading: true
    }
  },
  async beforeRouteEnter(to, from, next) {
    console.log('进入路由前进入路由前进入路由前')
    const { your } = to.params
    const { datas } = await getUserInfo({ type: 2, value: your }) || {}
    document.title = datas.realName // 将接口请求调整到路由进入前，兼容钉钉无法实时修改title的问题
    salesmanInfoBak = datas
    next()
  },
  async beforeRouteUpdate(to, from, next) {
    const { your } = to.params
    const { datas } = await getUserInfo({ type: 2, value: your }) || {}
    document.title = datas.realName // 将接口请求调整到路由进入前，兼容钉钉无法实时修改title的问题
    salesmanInfoBak = datas
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'managerMatch') {
      next({ name: 'home', replace: true })
    } else {
      next()
    }
  },
  async mounted() {
    this.salemansInfo = salesmanInfoBak
    this.loading = this.$toast.loading({ forbidClick: true, duration: 0 })
    const { your, sendHelloMsg } = this.$route.params
    const datas = this.salemansInfo
    // document.title = datas.realName
    if (sendHelloMsg) {
      await bindUserFriend({ targetUserId: datas.userId })
      this.$set(this.salemansInfo, 'callStatus', true)
    } else {
      const res = await getFriendBindState({ eaUserName: your })
      if (res.datas === '1') { // 1不能聊 2是能聊  3是能聊但是不能打电话
        this.$dialog.alert({
          title: '提示',
          message: '联系权限已失效',
          theme: 'round-button',
          confirmButtonColor: '#ff7b08',
          className: 'dialog-box'
        }).then(() => {
          this.$router.go(-1)
        })
        this.loading.clear()
        this.loading = false
        return
      }
      this.$set(this.salemansInfo, 'callStatus', res.datas !== '3')
    }
    this.loading.clear()
    this.loading = false
  },
  beforeDestroy() {
    disp.fire('em.chatroom.leave')
  }
}
</script>

<style lang="scss" scoped>

</style>
